* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: rgba(208, 208, 208, 0.125);
}

main {
  height: 100vh;
  max-width: 1600px;
  margin: 0 auto;
}

.main-top {
  margin-top: 4rem;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.index-container {
  background-color: #007bff;
}
.margin-cont {
  margin: 3rem;
}

.m-y-divider {
  margin: 1rem;
}

.react-resizable { 
  min-width: 320px !important;
}

/* Prevent text and element highlighting */
.no-select {
  user-select: none; /* Disables text selection */
  -webkit-user-select: none; /* Chrome, Safari, Edge */
  -ms-user-select: none; /* Internet Explorer */
}


@media (max-width: 800px) {
  .react-resizable { 
    min-width: 320px !important;
    width: 100% !important;
  }
}

/*****************************************/
/* FONTS */
/****************************************/

.commissioner-logo {
  font-family: "Commissioner", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "slnt" 0,
    "FLAR" 0,
    "VOLM" 0;
}

.roboto-thin {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.roboto-black {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.roboto-thin-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.roboto-light-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.roboto-regular-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.roboto-medium-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.roboto-bold-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.roboto-black-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: italic;
}

.icon-reset {
  text-decoration: none;
  color: black;
}
/*****************************************/
/* TITLE BOX */
/****************************************/

.pg-title {
  height:30px;
  width: 300px;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
}

/*****************************************/
/* ALERT */
/****************************************/

.alert-container {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
}

.alert {
  margin-bottom: 1rem;
  min-height: 50px;
  max-width: 450px;
  width: 90%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  border-radius: 10px;
  font-size: 1.1rem;
}

.alert-danger {
  border: 2px solid rgba(172, 4, 4, 0.983);
  color: rgba(172, 4, 4, 0.983);
}

.alert-green {
  border: 1px solid black;
  color: rgb(16, 16, 16);
}

/*****************************************/
/* NAVIGATION */
/****************************************/

nav {
  margin-top: 1rem;
  padding: 0 2rem;
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-left, .nav-right  {
  height: 70%;
}   

.nav-left {
  flex-grow: 0;
  flex-shrink: 1;
  display: flex;
  align-items: center;
  flex-basis: 50%;
}

.nav-left img {
  height: 65px;
  width: auto;
}

.nav-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-basis: 50%;
}

.nav-right div {
  height: 100%;
}

.dropdown-container {
  position: relative;
  display: inline-block;
}

.pill {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space out the content */
  min-width: 205px;
  height: 50px;
  border-radius: 25px;
  border: 0.5px solid rgba(0, 0, 0, 0.206);
  padding: 0 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  user-select: none;
}

.notification-pill {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
  height: 50px;
  border-radius: 25px;
  border: 0.5px solid rgba(0, 0, 0, 0.206);
  cursor: pointer;
  user-select: none;
  color: rgba(0, 0, 0, 0.659);
  font-size: 1.1rem;
  position: relative;
}

.nav-userimg {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 47px;
  width: 47px;
  border-radius: 50%;
  object-fit: cover;
  transition: left 1s ease, transform 1s ease;
}

.pill-greeting {
  flex-grow: 1; /* Allows the greeting to take up available space */
  text-align: center;
  padding: 0.85rem;
  opacity: 1;
  transition: opacity 0.5s ease;
  color: rgba(0, 0, 0, 0.698);
  font-size: 0.9rem;
}

.pill.center .nav-userimg {
  left: 50%;
  transform: translate(-50%, -50%);
}

.pill.center .pill-greeting {
  opacity: 0; /* Fade out greeting when centered */
}

.pill-arrow {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.dropdown-menu {
  position: absolute;
  top: 120%;
  right: 0;
  z-index: 1;
}

.notification-dropdown-menu {
  position: absolute;
  top: 120%;
  right: -510%;
  z-index: 1;
}

.dropdown-menu ul {
  min-width: 200px;
  background-color: white;
  border-radius: 20px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.notification-dropdown-menu ul {
  min-width: 320px;
  height: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  margin: 0 1rem;
}

/* Customize scrollbar for Webkit browsers */
.notification-dropdown-menu ul::-webkit-scrollbar {
  width: 7px; /* Width of the scrollbar */
}

.notification-dropdown-menu ul::-webkit-scrollbar-thumb {
  background-color: #cfcfcf;
  border-radius: 5px;
}

.notification-dropdown-menu ul::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Hover effect for the scrollbar */
}

.notification-dropdown-menu ul::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Track color */
}

.notification-dropdown-general {
  min-width: 320px;
  min-height: 330px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 20px;
}


.dropdown-menu li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 0.9rem;
}

li.unread {
  background-color: #efefefaa; /* Color for unread notifications */
}

.notification-dropdown-menu li {
  border-bottom: 0.1px solid rgba(64, 64, 64, 0.121);
  min-height: 80px;
  align-items: start;
}

.notification-link {
  text-decoration: none;
  color: black;
  display: block;
  padding: 10px;
  margin: 5px 0;
}

.dm-link {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: black;
}

.dropdown-menu li:hover {
  background-color: #f0f0f0;
}

.nav-right-wrapper {
  display: flex;
  gap: 1rem;
}

.notification-badge {
  color: white;
  position: absolute;
  top: -13%;
  left: 30px;
  background-color: #1d897d;
  font-size: 0.8rem;
  width: 25px;
  height: 25px !important;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media  (min-width: 700px) {
  nav {
    padding: 0 2rem;
  }
}

@media (max-width: 500px) {
  .pill {
    min-width: 130px;
    width: 100%;
    height: 50px;
  }

  .pill-greeting {
    display: none;
  }
  
  .nav-right-wrapper {
    gap: 0.2rem;
  }

  .notification-dropdown-menu {
    right: -220%;
    background-color: none;
  }

}

@media (max-width: 420px) {
  .pill {
    min-width: 90px;
  }

}

/*****************************************/
/* NAVSECTION */
/****************************************/

.navSection {
  margin: 1rem 0;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.inner-navSection {
  height: 100%;
  width: 100%;
  max-width: 1600px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.navSection-items {
  height: 40px;
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.676);
}

.navSection-items:hover {
  background-color: rgba(213, 213, 213, 0.268);
  border-radius: 25px;
  cursor: pointer;
}

.navSection-item-img {
  margin-right: 0.3rem;
}

.navSection-item-img img {
  scale: 1
}

@media  (max-width: 840px) {

  .navSection-items {
    height: 30px;
    width: 80px;
    font-size: 0.75rem;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.676); ;
  }
  
  .navSection-item-img img {
    scale: 0.65
  }
}

@media  (max-width: 620px) {
  .navSection {
    display: none;
  }

  nav {
    margin-bottom: 1.5rem;
  }
}

/*****************************************/
/* Landing Page */
/****************************************/

.index-main {
  max-width: 100vw;
}

/*****************************************/
/* Landing Page (Navigation) */
/****************************************/

.lp-nav-container {
  width: 100%;
  z-index: 100;
  background-color: white;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
}

.lp-nav-innercont {
  max-width: 1600px;
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.lp-nav-logo {
  height: 120px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lp-nav-logo img {
  scale: 1.6;
  height: 100%;
  width: auto;
}

.lp-nav-buttons {
  display: flex;
  justify-content: space-around;
}

.lp-nav-btns {
  margin: 1rem;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
}

.lp-nav-login {
  text-decoration: none;
  color: rgba(67,81,180,255);
  border: 2px solid rgba(67,81,180,255);
  width: 160px;
  border-radius: 25px;
  font-weight: 600;
}

.lp-nav-register {
  text-decoration: none;
  color: white;
  background-color: rgba(67,81,180,255);
  width: 160px;
  border-radius: 25px;
  font-weight: 600;
}

.lp-nav-register-arw {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
  transition: transform 0.5s ease;
}

.lp-nav-register:hover .lp-nav-register-arw {
  transform: translateX(0.3rem); /* Moves 0.5rem to the right */
}

.lp-nav-gridicon {
  font-size: 2rem;
  color: rgba(67,81,180,255);
}

@media (max-width: 610px) {
  .lp-nav-gridicon, .lp-nav-buttons > .lp-nav-register {
    display: none;
  }

}

/*****************************************/
/* Landing Page (Hero)*/
/****************************************/

.divider {
  height: 120px;
}

.lp-hero-container {
  height: 90vh;
  position: relative;
  overflow: hidden; /* Ensures the image is hidden when out of view */
}

.lp-hero-inner-cont {
  height: 60%;
  min-height: 300px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.827);
  display: flex;
  justify-content: center;
}

.lp-hero-header {
  max-width: 1600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.lp-hh-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  font-size: 4.22rem;
  font-weight: 100;
  z-index: 10;
}

.lp-hh-title div:last-child {
  margin-top: 0.5rem;
  color: white;
  font-size: 1.1rem;
  font-weight: 400;
  z-index: 10;
}

.diagonal-bounce-animation {
  height: 75%;
  width: auto;
  position: absolute;
  bottom: -100px; /* Starts out of view at the bottom */
  left: -100px; /* Starts out of view at the left */
  animation: diagonalBounce 8s cubic-bezier(0.45, 0.05, 0.55, 0.95) forwards;
  z-index: 1; /* Image is under the text */
}

@keyframes diagonalBounce {
  0% {
    bottom: -100px; /* Start offscreen at the bottom */
    left: -100px;   /* Start offscreen at the left */
    opacity: 0;     /* Invisible at the start */
  }
  30% {
    bottom: 90%;    /* Move diagonally to the top center */
    left: 50%;
    transform: translateX(-50%);
    opacity: 1;     /* Fade in while moving */
  }
  60% {
    bottom: 55%;    /* Move downwards to simulate bounce */
    left: 50%;
    transform: translateX(-50%);
  }
  100% {
    bottom: -20%;    /* Settle at the center vertically */
    left: 80%;
    transform: translate(-50%, -50%);
  }
}

.lp-hero-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Responsive grid */
  width: 100%;
  height: 60%;
  background-color: rgba(0, 0, 0, 0.827);
}

/* Grid item styling */
.lp-grid-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.827); /* Default background */
  color: white; /* Default text color */
  font-size: clamp(2rem, 5vw, 4.22rem); /* Responsive text size */
  font-weight: 100;
  transition: background-color 0.5s ease, color 0.5s ease; /* Smooth transitions */
  animation: colorChange 17s infinite; /* Apply animation */
}

/* Keyframes for the animation */
@keyframes colorChange {
  0%, 100% { background-color: white; color: white; } /* Initial and final state */
  25% { background-color: rgba(67,81,180,255); color: yellow; } /* First grid item color */
  50% { background-color: rgba(0, 0, 0, 0.827); color: lightgreen; } /* Second grid item color */
  75% { background-color: rgba(0, 0, 0, 0.827); color: orange; } /* Third grid item color */
}

/* Stagger the animation for each grid item */
.lp-grid-item:nth-child(1) {
  animation-delay: 1s; /* Start immediately */
}

.lp-grid-item:nth-child(2) {
  animation-delay: 3s; /* Start after 2 seconds */
}

.lp-grid-item:nth-child(3) {
  animation-delay: 10s; /* Start after 4 seconds */
}

.lp-grid-item:nth-child(4) {
  animation-delay: 16s; /* Start after 6 seconds */
}

.lp-gi-box {
  font-weight: 500;
  font-size: 5rem;
  padding: 1rem;
}

@media (max-width: 1100px) {
  .lp-hero-grid {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)); /* Responsive grid */
  }

  .lp-hero-container {
    min-height: 100vh;
    height: auto;
    overflow: hidden
  }

  .lp-hero-inner-cont {
    height: 60%;
    min-height: 500px;
  }

}

@media (max-width: 900px) {
  .lp-hh-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    font-size: 3rem;
    font-weight: 100;
    z-index: 10;
    width: 80%;
  }

  .lp-hero-grid {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsive grid */
  }
}

@media (max-width: 650px) {

  .lp-hero-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Responsive grid */
  }
}


/*****************************************/
/* USER DASHBOARD */
/****************************************/

.db-welcome-cont {
  padding: 2rem 0 0 3rem;
}

.db-welcome-cont div {
  margin: 1rem;
  color: rgba(24, 24, 24, 0.869);
}

.db-welcome-cont div:last-child {
  padding-top: 1rem;
  font-size: 1.2rem;
}

.db-profile-msg {
  margin: 1rem;
}

.dashboard-welcome {
  display: flex;
  padding: 1rem 1rem 1rem 3rem;
}

.dw-left {
  flex-basis: 60%;
}

.dwl-bottom {
  height: 300px;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
}

.dwl-buttons {
  width: 100%;
  background-color: rgb(222, 222, 222);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 0.8rem;
  text-decoration: none;
  color: black;
}

.dwl-buttons img {
  height: 35%;
  width: 35%;
  object-fit: contain;
  margin-bottom: 1rem;
}


.dw-right {
  padding: 2rem 0 0 2rem;
  flex-basis: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 450px;
}

.dwl-bottom div:hover > img {
  height: 30%;
  width: 30%;
}

.db-tasklist-title {
align-self: flex-start;
font-size: 1.2rem;
margin: 1rem;
}

.timeline {
list-style: none;
width: 100%;
padding: 0;
position: relative;
overflow-y: auto;
}

.timeline:before {
content: '';
position: absolute;
left: 20px; /* Adjust this to center the line */
top: 10%; /* Adjust to start the line at the first dot */
bottom: -50%; /* Adjust to end the line at the last dot */
width: 2px;
background: #C5CAE9;
}

.timeline-item {
position: relative;
padding: 20px 0;
padding-left: 40px;
text-align: justify;
}

.timeline-item:before {
content: '';
position: absolute;
left: 13px; /* Adjust this to center the dots */
top: 20px;
width: 16px;
height: 16px;
border-radius: 50%;
background: #03A9F4;
}
.timeline-item:nth-child(odd):before {
background: #FF9800;
}
.timeline-item:nth-child(even):before {
background: #8E24AA;
}
.timeline-item:last-child:before {
background: #4CAF50;
}
.date {
font-weight: bold;
margin-bottom: 10px;
}

.timeline-item .details {
  padding: 0.5rem 2rem 0 0;
}

.dashboard-annouce {
  margin: 1rem;
}

.da-title {
  margin: 1rem 0;
  font-size: 1.2rem;
  font-weight: 700;
}

.da-container {
  min-height: 300px;
  background-color: rgb(222, 222, 222);
  padding: 0 1rem 1rem 4rem;
  display: flex;
}

.da-c-l {
  flex-basis: 63%;
}

.da-c-r {
  flex-basis: 37%;
  background-color: rgba(35, 35, 35, 0.701);
}

.da-items {
  min-height: 120px;
  width: 80%;
  background-color: rgba(244, 242, 242, 0.915);
  border-radius: 10px;
  margin-top: 3.5rem;
  position: relative;
}

.da-img-container {
  position: absolute;
  left: -39px;
  top: -25px;
  display: flex;
  align-items: flex-start;
}

.da-user-img {
  height: 32px;
  width: 32px;
}

.da-user-img img {
  height: 100%;
  width: 100%;
  object-fit: fill;
  border-radius: 100px;
}

.da-user-contact {
  margin-left: 7px;
  font-weight: 600;
  font-size: 0.9rem;
}

.da-items-content {
  padding: 1rem;
}
/*****************************************/

.user-dashboard {
  min-height: 100%;
  height: auto;
  padding: 2rem 1rem;
}

.welcome-user {
  margin-left: 1rem;
  font-size: 1.3rem;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
}

.welcome-user span:first-child {
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.welcome-user span:last-child {
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.masonry-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  height: 100%;
  width: 100%;
}

.masonry-grid > div {
  box-sizing: border-box; /* Ensure padding is included in the width calculation */
  min-width: 320px;
}

.widget_intro_container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 1 / -1;
}

.wi_wrapper {
  width: 100%;
  height: 100%;
  max-width: 1100px;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 1 / -1;
}

.wi_wrapper > img {
  height: 80%;
  width: 80%;
}


@media (min-width: 1001px) {
  .masonry-grid > div {
    flex: 1 1 auto; /* Allow items to grow to fill space, but not shrink smaller than their content */
    min-width: 300px; /* Set a minimum width to ensure items don't shrink too much */
    max-width: 100%; /* Prevent items from exceeding container width */
    padding: 1rem;
  }

  .masonry-grid > div:nth-child(n) {
    /* Default behavior for all items */
    flex: 1 1 auto;
  }

  /* Target last child of each row */
  .masonry-grid > div:last-child {
    flex: 0 0 auto; /* Prevent the last item from growing or shrinking */
    align-self: flex-start; /* Ensure alignment matches other items */
  }

    /* Target last child of each row */
    .masonry-grid > div:first-child {
      flex: 0 1 auto; /* Prevent the last item from growing or shrinking */
      align-self: flex-start; /* Ensure alignment matches other items */
    }
}

@media (max-width: 1000px) {
  .masonry-grid {
    display: grid;
    padding: 0 1rem;
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 750px) {
  .masonry-grid {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
}

.resize-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.grid-item {
  cursor: grab;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.grid-item.dragging {
  opacity: 0.7;  /* Optional: Add an effect when dragging */
}

.layout-removebtn:hover {
  background-color: #28a798 !important;
}

/*****************************************/
/* USERDB- QUICK NAV COMPONENT */
/****************************************/

.nav-gsm-content {
  height: 100%;
  width: 100%;
  min-width: 300px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 0.8rem;
}

.nav-gsm-items {
  border: 2px solid rgba(78, 78, 78, 0.357);
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.745);
  position: relative;
  font-size: 1.1rem;
  min-width: 145px;
}

.nav-gsm-items span:first-child{
  font-size: 2.4rem;
  margin-right: 0.2rem;
}

.nav-gsm-items span:last-child{
  font-size: 1.7rem;
  position: absolute;
  bottom: 8%;
  right: 15%;
}

.nav-gsm-items1 {
  background-color: rgb(166, 176, 255);
  color: rgb(17, 28, 117);
}

.nav-gsm-items2 {
  background-color: rgb(130, 248, 143);
  color: rgb(33, 33, 33);
}

.nav-gsm-items3 {
  background-color: rgb(245, 200, 136);
  color: rgb(104, 49, 5);
}

.nav-gsm-items4 {
  background-color: rgb(226, 177, 246);
  color: rgb(11, 11, 11);
}


/*****************************************/
/* TEAM COMPONENT */
/****************************************/

.teams-gsm-content {
  min-height: 300px;
}

.teams-user-item {
  height: 88px;
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  color: black;
  border-bottom: 1px solid rgba(169, 169, 169, 0.389);
  text-decoration: none;
}

.teams-user-item:hover {
  background-color: rgba(225, 225, 225, 0.613);
  border: none;
}

.teams-user-item img {
  height: 100%;
  width: 55px;
  margin-right: 1rem;
  object-fit: cover;
}

.teams-user-info {
  text-transform: capitalize;
  font-size: 0.85rem;
  font-weight: 600;
}

.teams-user-info div:last-child {
  color: rgba(0, 0, 0, 0.374)
}

.lr-logo {
  width: 55px;
  font-size: 2.2rem;
  margin-right: 1rem;
}

.lr-hero {
  min-height: 350px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lr-hero div {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.lr-hero-logo {
  font-size: 3rem;
  color: rgb(4, 4, 133);
}

.lr-hero-text {
  margin: 1rem;
  font-size: 0.9rem;
  font-weight: 600;
  color: rgb(4, 4, 133);
}

/*****************************************/
/* WIP Page */
/****************************************/

.wip {
  background-color: rgba(236,240,241,255);
}

.wip-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.wip-main img {
  height: 55%;
  width: 55%;
  object-fit: contain;
}

.wip-message {
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 1.3rem;
  text-align: justify;
  color: rgba(0, 0, 0, 0.722);
}

.wip-button {
  margin-top: 2rem;
  background-color: #0f78a9;
  height: 60px;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

.wip-button > button {
  border-radius: 20px;
  height: 60px;
  width: 300px;
  border: none;
  font-size: 1rem;
  background-color: #0f78a9;
  font-weight: 600;
  color: rgba(245, 245, 245, 0.863);
}


/*****************************************/
/* PROFILE */
/****************************************/

.pfp-backdrop {
  height: 300px;
  width: 100%;
  background-color: rgb(227, 227, 227);
}

.pfp-img-container {
  height: 200px;
  width: 200px;
  position: relative;
  top: 85%;
  left: 50px;
}

.pfp-img-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0.5px 0.5px 5px 1px rgba(0, 0, 0, 0.326);
}

.pfp-main-container {
  height: 500px;
  margin-left: 10rem;
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
}

.pfp-user-container {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pfp-cont-container {
  flex-basis: 45%;
  background-color: rgb(227, 227, 227);
}

.pfp-user-particulars {
  height: 220px;
  background-color: rgb(227, 227, 227);
  padding: 2rem 0 0 7rem;
}

/*****************************************/
/* Admin-Dashboard */
/****************************************/

.admin-hero {
  height: 150px;
  padding: 3rem;
}

.admin-dashboard {
  padding: 2rem;
  height: 400px;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
}

.admin-dashboard div {
  width: 100%;
  background-color: rgb(222, 222, 222);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 0.8rem;
}

.user-list-wrapper {
  height: 100px;
}

.employee-list {
  list-style: decimal;
  list-style-position: inside;
}

.employee-list li {
  margin-top: 1rem;
}

.employee-list li:hover {
  color: blue;
  cursor: pointer;
  font-weight: 600;
}

.adm-dbmain {
  width: 100%;
  min-height: 500px;
  padding: 2rem;
  font-size: 0.95rem;
}

.adm-grid {
  display: grid;
  height: 100%;
  grid-template-columns: 2.2fr 0.8fr;
}

.adm-grid-main {
  padding-right: 2rem;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.adm-grid-main-fullwidth {
  padding: 1rem;
  padding-right: 2rem;
}

.adm-grid-section {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
}

.adm-mobilegrid-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.grid-section-main {
  height: 100%;
  width: 100%;
  padding: 1rem;
}

.adm-db-people-title {
  font-size: 1.6rem;
  margin: 2rem 0;
}

.people-btn {
  background-color: #b67417;
  height: 42px;
  max-width: 200px; 
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  margin-bottom: 1rem;
  color: white;
  text-decoration: none;
}

@media (max-width: 947px) {
  .adm-grid-main-fullwidth {
    padding-right: 3rem;
  }
}

@media (max-width: 700px) {
  .adm-grid {
    display: grid;
    height: 100%;
    grid-template-columns: 1fr 1fr;
  }

  .adm-dbmain {
    padding: 1rem;
  }
}

@media (max-width: 550px) {
  .adm-grid {
    display: flex;
    flex-direction: column;
  }

  .adm-grid-section { 
    order: 1;
  }

  .adm-grid-main-wrapper {
    order: 2;
  }

  .adm-grid-main {
    padding: 0;
    margin-top: 1rem;
  }

  .adm-grid-main-fullwidth {
    padding: 0;
  }
}

.adm-mobilegrid-section {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(65px, 1fr));
  height: auto;
}

.small-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.small-grid > .grid-mobilesection-item > .contact-item-img{
  max-height: 200px;
  max-width: 200px;
  height: auto;
  width: 100%;
  margin: 1rem;
}

.grid-mobilesection-item {
  margin: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid-mobilesection-item > .contact-item-img {
  height: 60px;
  width: 60px;
}

.contact-item-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.hover-box {
  position: absolute;
  bottom: -30px; /* Adjust this value to position the box below the image */
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  color: black;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap; /* Prevent the text from wrapping */
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none; /* Prevent interaction with the box */
}

/* Show the hover box when the user hovers over the image */
.contact-item-img:hover .hover-box {
  opacity: 1;
}

/* Ensure the modal is centered and overlay covers the whole screen */
.people-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.people-modal-content {
  background-color: #fff;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  min-height: 400px;
  max-height: 80%;
  overflow-y: auto;
  padding: 4rem 2rem;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  color: black;
  background-color: transparent;
  border: none;
  font-size: 1.3rem;
  cursor: pointer;
}

.all-users-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.all-users-grid .grid-mobilesection-item {
  display: flex;
  flex-wrap: wrap;
}

.people-toggle-btn {
  height: 35px;
  width: 200px;
}

@media (max-width: 550px) {
  .small-grid > .grid-mobilesection-item > .contact-item-img{
    height: 60px;
    width: 60px;
  }
}

/*****************************************/
/* OUR PEOPLE (COMPONENT) */
/****************************************/

.people-grid-section {
  padding: 1rem;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  align-items: start;
  justify-content: start;
}

.people-grid-section-mini {
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.people-grid-section-mini > .grid-section-item {
  min-width: 300px;
}

.grid-section-item {
  background-color: white;
  border-radius: 20px;
  padding: 1rem;
  min-height: 350px;
  height: 100%;
  font-weight: 600;
  position: relative;
}

#grid-section-item {
  max-width: 300px;
}

.contact-item-img {
  height: 70px;
  width: 70px;
  margin-bottom: 1rem;
  position: relative;
}

.contact-item-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.gs-link-btn {
  font-size: 2rem;
  position: absolute;
  top: 5%;
  right: 5%;
}

.gs-status {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: green;
  position: absolute;
  top: 72%;
  right: -5%;
}

.contact-item-particulars {
  height: 60px;
  text-transform: capitalize;
}

.ci-name {
  margin-bottom: 0.3rem;
}

.ci-position {
  color: rgba(21, 21, 21, 0.618)
}

.contact-item-details {
  background-color: rgba(215, 215, 215, 0.256);
  border-radius: 10px;
  padding: 0.5rem;
  min-height: 150px;
  height: auto;       
  overflow: hidden;         
  word-wrap: break-word; /* Ensures long words (e.g., email addresses) break into multiple lines */
  word-break: break-all;
}

.ci-work-particulars {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  margin-bottom: 1rem;
}

.ci-work-particulars div {
  display: flex;
  flex-direction: column;
}

.ci-wp-title {
  margin-bottom: 0.2rem;
  color: rgba(21, 21, 21, 0.618)
}

.ci-wp-desc {
  font-size: 0.9rem;
}

.ci-email {
  display: flex;
  align-items: center;
}

.ci-email span:first-child{
  margin-right: 0.5rem;
  font-size: 1.3rem;
}

.ci-email span:last-child{
  font-size: 0.85rem;
}

#people-dropdown-menu ul {
  height: 60px;
  flex-direction: row;
  align-items: center;
  justify-content: center;

}

#people-dropdown-menu ul > li {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-basis: 20%;
  font-size: 1.5rem;

}

.rm-decor {
  text-decoration: none;
  color: rgb(63, 63, 63);
}

@media (max-width: 550px) {

.adm-db-people-title {
  margin: 1rem 0;
}

.grid-mobilesection-item {
  margin: 0.2rem;
}

.grid-mobilesection-item > .contact-item-img {
  height: 55px;
  width: 55px;
}

.hover-box {
  position: absolute;
  bottom: -30px; /* Adjust this value to position the box below the image */
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  color: black;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap; /* Prevent the text from wrapping */
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none; /* Prevent interaction with the box */
}

/* Show the hover box when the user hovers over the image */
.contact-item-img:hover .hover-box {
  opacity: 1;
}

/* Ensure the modal is centered and overlay covers the whole screen */
.people-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.people-modal-content {
  background-color: #fff;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  min-height: 400px;
  max-height: 80%;
  overflow-y: auto;
  padding: 4rem 2rem;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  color: black;
  background-color: transparent;
  border: none;
  font-size: 1.3rem;
  cursor: pointer;
}

.all-users-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.all-users-grid .grid-mobilesection-item {
  display: flex;
  flex-wrap: wrap;
}

.people-toggle-btn {
  height: 35px;
  width: 200px;
}
}

@media (max-width: 700px) {
  .people-grid-section-mini {
    justify-content: center;
  }

  .people-grid-section {
    justify-content: center;
  }
}

/*****************************************/
/* RECRUITMENT (COMPONENT) */
/****************************************/

.gsm-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
}

.gsm-title div:last-child {
  font-size: 2rem;
}

.gsm-content {
  min-height: 350px;
  padding: 1rem;
  background-color: #ffffffca;
}

.gsm-r-grid {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
  min-height: 150px;
}

.gsm-r-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 600;
  border: 1px solid rgba(0, 0, 0, 0.421);
}

.gsm-r-item div:first-child {
  font-size: 1.8rem;
  margin-bottom: 0.2rem;
}

.gsm-r-item3 {
  margin: 1rem 0;
  border: 1px solid rgba(0, 0, 0, 0.421);
  padding: 1rem;
}

.gsm-i3-title {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.gsm-i3-title div:first-child{
  font-size: 1.8rem;
  margin-right: 1rem;
}

@media (max-width: 800px) {
  .gsm-title-none {
    height: 1px;
  }
}

@media (max-width: 964px) {
  .gsm-titleadmin-none {
    height: 0;
  }

  .gsm-titleadmin-none {
    margin-top: 0;
  }
  
}

/*****************************************/
/* CALENDAR (COMPONENT) */
/****************************************/

#calendar-component {
  border: 1px solid rgba(0, 0, 0, 0.511);
  background-color: transparent;
}

.calendar-fulldate {
  font-size: 0.9rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.calendar-abbreviated {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.calendar-display {
  height: 100px;
  width: 120px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  border: 0.5px solid rgba(0, 0, 0, 0.309);
}

.calendar-clock {
  font-size: 2.5rem;
}

.calendar-upcoming {
  margin: 1rem 0;
  font-size: 0.85rem;
}

.calendar-deco {
  background-color: #007bff;
  height: 105px;
  width: 100%;
}

.calendar-deco img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/*****************************************/
/* LEAVE DASHBOARD (COMPONENT) */
/****************************************/

.ld-gsm-content {
  min-height: 100%;
  min-width: 320px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ld-gsm-content section {
  padding: 1.1rem;
  height: 88px;
  width: 100%;
  border-bottom: 1px solid rgba(238, 238, 238, 0.647);
  display: flex;
}

.ld-gsm-content section:last-child {
  border-bottom: none;
}


.ld-icon {
  width: 220px;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  margin-right: 0.1rem;
}

.ld-icon-circle {
  color: rgba(0, 0, 0, 0.399);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 50px;
  font-size: 1.8rem;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.ld-desc {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  font-size: 0.9rem;
}

.ld-ic-1 {
  background-color: rgb(247, 181, 247);
  color: rgb(147, 78, 90);
}

.ld-ic-2 {
  background-color: rgb(232, 196, 130);
  color: rgb(108, 81, 31);
}

.ld-ic-3 {
  background-color: rgb(150, 244, 150);
  color: green;
}

.ld-ic-4 {
  background-color: rgb(174, 174, 255);
  color: rgb(65, 50, 200);
}

/*****************************************/
/* ATTENDANCE (COMPONENT) */
/****************************************/
/* Add this to your CSS file */
.attendance-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.attendance-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
}

.attendance-image-preview {
  width: 100%;
  height: auto;
  margin-top: 10px;
}

/*****************************************/
/* LEAVE */
/****************************************/

.lc-main { 
  min-height: 250px;
}

.lc-title {
  display: flex;
  align-items: center;
  height: 70px;
}

.lc-title div:last-child {
  margin-left: 1rem;
  font-size: 2rem;
  color: rgb(15, 15, 15);
  text-decoration: none;
}

.lc-content {
  min-height: 250px;
  padding: 1rem;
  background-color: #ffffffca;
}

.lc-content section:first-child {
  min-height: 80px;
  display: flex;
  align-items: center;
}

.lc-c-img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin-right: 1rem;
}

.lc-c-img img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.lc-c-user {
  text-transform: capitalize;
}

.lc-c-user {
  font-size: 0.85rem;
  color: rgba(0, 0, 0, 0.614);
}

.lc-c-user div:first-child {
  font-weight: 600;
  font-size: 0.95rem;
  color: black;
}

.lc-content section:last-child {
  min-height: 130px;
  display: flex;
  align-items: center;
}

.lc-date-icon {
  border: 1px solid rgba(0, 0, 0, 0.423);
  height: 100px;
  min-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  border-radius: 10px;
  margin-right: 1.5rem;
}

.lc-reason {
  height: 100px;
  width: 100%;
  background-color: #dcdcdc5d;
  border-radius: 10px;
  padding: 1rem;
  text-align: justify;
  word-break: break-word; 
  overflow: auto;
  -webkit-box-orient: vertical; 
  box-sizing: border-box;
}

@media (max-width: 780px) {
  .lc-content section:last-child {
    flex-direction: column;
    align-items: start;
  }

  .lc-date-icon {
    width: 100%;
    margin-bottom: 1rem;
  }
}

/*****************************************/
/* PEOPLE */
/****************************************/

.people-dashboard {
  margin: 2rem 0;
  min-height: 100%;
  height: auto;
}

/*****************************************/
/* ADMIN USER MANAGEMENT */
/****************************************/

.usermng-pfp {
  padding: 0 3rem;
}
.usermng-title {
  font-size: 1.5rem;
}

.usermng-user-particulars-container {
  margin-top: 4rem;
  display: flex;
}

.usermng-avatar-cont {
  height: 220px; 
  width: 220px;
}

.usermng-avatar-cont img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.usermng-particular {
  flex-basis: 60%;
  padding: 1rem 0 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.usermng-particular div > div {
  margin-top: 0.5rem;
}

.usermng-actions {
  margin-top: 5rem;
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: 1fr 1fr 1fr;
}

.action-item {
  background-color: rgb(222, 222, 222);
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.705);
}

.action-item img {
  margin-bottom: 1rem;
}


.action-item:hover {
  font-size: 1.2rem;
}

@media (max-width: 750px) {
  .usermng-actions {
    margin-top: 5rem;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  }
}

/*****************************************/
/* ADMIN USER- LEAVE MANAGEMENT */
/****************************************/

.usermng-leave-actions {
  margin-top: 5rem;
  display: flex;
}

.usermng-leave-actions {
  width: 100%;
  margin-top: 8rem;
  display: flex;
}

.usermng-leave-form {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr)); /* Responsive columns */
  grid-gap: 1rem;
}

.usermng-leave-actions div {
  width: 100%;
}

.usermng-leave-actions div input {
  background-color: transparent;
  border: none;
  margin-left: 1rem;
  font-size: 1rem;
}

.usermng-leave-actions div input:focus {
  border: none;
  border-bottom: 1px solid black;
}

.usermng-leave-form button {
  font-size: 1rem;
  font-weight: 600;
  min-height: 50px;
  margin: 2rem 0;
  width: 80%;
}

.leaveapp-form {
  height: 50px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  grid-template-rows: 2fr;
  grid-gap: 1rem;
}

.leaveapp-form div {
  border: 1px solid rgba(0, 0, 0, 0.14);
  display: flex;
  align-items: center;
  justify-content: center;
}

.leaveapp-header {
  margin: 4rem 0 2rem 0;
}

.button-wrapper {
  display: flex;
}

.button-wrapper > .button {
  border-radius: 0;
  height: 60px;
  width: 220px;
  background-color: #636363;
  margin: 5rem 2rem 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.875);
  border: none;
  text-decoration: none;
}

.button-wrapper > .button:hover {
  font-size: 1.1rem;
}

@media (max-width: 1023px) {
  .leaveapp-form {
    height: 150px;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    grid-template-rows: auto;
    grid-gap: 1rem;
  }
}

@media (max-width: 614px) {
  .usermng-user-particulars-container {
    flex-direction: column;
  }

  .usermng-particular {
    flex-basis: 100%;
    padding: 1rem 0;
  }
}


@media (max-width: 551px) {
  .leaveapp-form {
    height: 300px;
  }

  .button-wrapper {
    margin-top: 2rem;
    justify-content: space-around;
    flex-direction: column;
  }
  
  .button-wrapper > .button {
    margin: 0;
    margin-bottom: 1rem;
    width: 100%;
    min-width: 220px;
  }
}



/*****************************************/
/* LEAVE FORM */
/****************************************/

.leaveform-wrapper {
  margin-top: 3rem;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.leaveform-company-header {
  height: 80px;
  width: 80%;
  max-width: 900px;
  font-size: 1.5rem;
  margin-bottom: 4rem;
  text-align: center;
  background-color: rgba(35, 35, 35, 0.839);
  color: rgba(255, 255, 255, 0.954);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.leaveform-header {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 2rem;
}

.leaveform-content-container {
  width: 80%;
  max-width: 900px;
  height: 100vh;
}

/* Customize the label (the container) */
.leaveform-radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.leaveform-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 1px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #f4f4f4;
  border: 0.5px solid #707070;
  border-radius: 50%;
}

/* When the radio button is checked, add a blue background */
.leaveform-radio input:checked ~ .checkmark {
  background-color: #707070;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.leaveform-radio input:checked ~ .checkmark:after {
  display: block;
}

.radio-container {
  height: auto;
  max-width: 900px;
  padding: 2rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-template-rows: repeat(auto, 1fr); 
  grid-gap: 1rem; 
}

.laf-date {
  display: flex;
  margin-bottom: 1rem;
}

.laf-date-resume {
  margin-bottom: 0.5rem;
}

.laf-date-resume > div > span {
  margin-right: 1rem;
}

.laf-date span {
  margin-right: 1rem;
}

.laf-input {
  margin-top: 0.5rem;
  height: 25px;
  width: 200px;
  background-color: transparent;
  font-size: 1rem;
  border: 1px solid rgba(94, 94, 94, 0.518);
  padding-left: 10px;
  margin-right: 1rem;
}

.laf-label {
  display: block;
  width: 200px;
}

.laf-textbox {
  margin-bottom: 1rem;
}

.laf-textbox > span {
  display: inline-block;
  width: 130px;
}

.custom-textarea {
  width: 100%;
  height: 80px;
  padding: 0.5rem;
}

.submit-btn {
  height: 40px;
  width: 200px;
  border: none;
  background-color: rgba(35, 35, 35, 0.893);
  color: rgba(255, 255, 255, 0.954);
  border-radius: 10px;
  margin-bottom: 5rem;
}

.time-selection-container {
  margin-bottom: 1rem;
  max-width:550px
}

.ts-title {
  margin-bottom: 0.5rem;
}

.ts-wrapper {
  display: flex;
  align-items: center;
  justify-content: stretch;
  flex-wrap: wrap;
  gap: 1rem;
}

.ts-wrapper > label > span {
  display: inline-block;
  width: 50px;
}

.ts-wrapper > label > .to-span {
  text-align: center;
}

.ts-input {
  min-width: 200px;
  height: 25px;
  padding: 0.5rem;
  background-color: transparent;
  border: 0.5px solid rgba(0, 0, 0, 0.357);
  font-size: 1rem;
}

.lf-duration {
  margin-bottom: 1rem;
}


@media (max-width: 800px) {
  .leaveform-wrapper {
    width: 100%;
    height: auto;
    padding: 1rem;
  }
}

@media (max-width: 525px) {
  .laf-date {
    flex-direction: column;
    margin-bottom: 2rem;
  }

  .laf-date span {
    margin: 0;
    display: inline-block;
    width: 100px;
  }
}

/*****************************************/
/* LEAVE RECORDS  */
/****************************************/

.lr-main {
  padding: 2rem;
}

.dropdown-btn {
  border: none;
  height: 40px;
  width: 200px;
  margin-bottom: 1rem;
}

.leave-list {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 1rem;
}

.lr-li {
  height: auto;
  width: 100%;
  list-style-position: outside;
  list-style: none;
}

.lr-content {
  margin-bottom: 1rem;
  padding: 1rem;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  max-width: 600px;
  background-color: rgba(255, 255, 255, 0.889);
  height: 100%;
  position: relative;
}

.lr-content-input {
  display: flex;
  margin-bottom: 1rem;
}

.lr-c-i-title {
  font-weight: 600;
  margin-right: 0.5rem;
}

.lr-c-i-desc {
  text-transform: capitalize;
  margin-right: 2rem;
}

.lr-status {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.lr-status div {
  background-color: #eeeeee;
  height: 30px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination {
  margin: 3rem 0;
  height: 200px;
  display: flex;
}

.pagination button {
  height: 35px;
  width: 35px;
  border: none;
}

.pagination .pg-action-btn {
  width: 100px;
  border: 1px solid rgba(0, 0, 0, 0.199);
  color: black;
  font-weight: 800;
  font-size: 1.5rem;
  margin: 0 1rem;
}

.pagination .pg-action-btn:hover {
  background-color: #9e9e9e;
}

.pagination > .active {
  background-color: #9e9e9e;
}

@media (max-width:1250px) {
  .nl-alert {
    margin: 1rem;
  }
}

@media (max-width: 680px) {
  .lr-main {
    padding: 1rem;
  }
  
  .lr-content {
    padding: 0;
    width: 90%;
  }
}

.correction-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.submitted-modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.correction-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  min-width: 300px;
  max-width: 450px;
  width: 100%;
  max-height: 80%;
  min-height: 450px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin: 1rem;
}

.correction-modal-content h3 {
  margin-bottom: 20px;
}

.correction-modal-content label {
  display: block;
  margin-bottom: 5px;
}

.correction-modal-content input,
.correction-modal-content textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.correction-modal-content textarea[name="reason"] {
  resize: vertical; 
  min-height: 50px;
  max-height: 250px;
}

.correction-modal-btn {
  padding: 10px 20px;
  margin-right: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.correction-modal-btn[type="submit"] {
  background-color: #4CAF50;
  color: white;
}

.correction-radio-input {
  display: block !important;
}

.correction-btn {
  font-size: 1.7rem;
  font-weight: 800;
  position: absolute;
  right: 4%;
  top: 4%;
}

.correction-btn:hover {
  scale: 1.1;
  cursor: pointer;
}

.cm-hd {
  display: flex;
  align-items: center;
}

.cm-hd > input {
  margin-top: 1rem;
  flex-basis: 10%;
}

.cm-hd > div {
  flex-basis: 90%;
  flex-shrink: 1;
}





/*****************************************/
/* PUBLIC HOLIDAY FORM */
/****************************************/

.ph-container {
  max-width: 850px;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.ph-header {
  align-self: flex-start;
  margin-top: 3rem;
}

.ph-form {
  display: flex;
  flex-direction: column;
}

.ph-desc {
  text-align: justify;
}

.ph-input {
  margin-top: 0.5rem;
  height: 30px;
  width: 250px;
  background-color: transparent;
  font-size: 1rem;
  border: 1px solid rgba(94, 94, 94, 0.518);
  padding-left: 10px;
  margin-right: 1rem;
}

.ph-maininput-wrapper {
  height: 100px;
  display: flex;
  align-items: center;
  background-color: #c3c3c3;
  margin-bottom: 2rem;
}

.ph-maininput {
  margin-top: 0.5rem;
  height: 30px;
  width: 250px;
  background-color: transparent;
  font-size: 1rem;
  border: 1px solid rgba(94, 94, 94, 0.518);
  padding-left: 10px;
  margin-right: 1rem;
}

.ph-addholiday-btn {
  height: 35px;
  width: 250px;
  background-color: rgba(30, 30, 31, 0.828);
  font-size: 0.8rem;
  padding-left: 10px;
  margin-right: 1rem;
  color: rgba(255, 255, 255, 0.866);
  border-radius: 10px;
}

@media (max-width: 1250px) {
  .dropdown-btn {
    margin-left: 1rem;
  }
  
  .leave-list {
    margin-top: 2rem;
  }
  
  .lr-content {
    padding: 1rem;
    display: inline-block;
    vertical-align: top;
    margin-left: 1rem;
    max-width: 600px;
  }
}

@media (max-width: 900px) {
  .ph-container {
    padding: 0 1rem;
  }
}

@media (max-width: 655px) {
  .ph-maininput-wrapper {
    min-height: 100px;
    height: auto;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    padding: 1rem 0;
  }

  .ph-maininput-wrapper > input {
    margin-bottom: 1rem;
  }
  
}


/*****************************************/
/* PUBLIC HOLIDAY  */
/****************************************/

.ph-main {
  display: flex;
  flex-direction: column;
}

.ph-decor div {
  height: 380px;
}

.ph-decor div img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.ph-main-content {
  padding: 2rem;
  display: flex;
  min-height: 100%;
}

.ph-data {
  flex-basis: 60%;
  min-height: 100%;
  height: auto;
}

.ph-calendar {
  flex-basis: 40%;
  min-height: 100%;
  padding: 1rem 0 0 1rem;
}

.holiday-table {
  width: 100%;
  max-width: 1000px;
  border-collapse: collapse;
  margin-top: 1rem;
}

.holiday-table th,
.holiday-table td {
  padding: 8px;
  text-align: left;
  max-width: 70px;
  height: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.39);
}

.holiday-table th {
  background-color: #5a5a5a;
  color: rgba(255, 255, 255, 0.82);
  height: 40px;
  font-weight: bold;
}

/* Ensure the calendar container is centered */
.ph-calendar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

/* Fixed 200px width and height for the calendar */
.ph-calendar-wrapper {
  min-width: 100%;
  height: 100%;
  position: relative;
}

.ph-calendar {
  width: 100%;
  height: 100%;
}

/* Override the default background color of the calendar */
.ph-calendar .react-calendar {
  background-color: transparent; /* Change this to the color you want */
  border-radius: 8px; /* Optional: add border-radius for rounded corners */
  border: 1px solid #ddd; /* Optional: border around the calendar */
}

/* For calendar tiles (individual dates) */
.ph-calendar .react-calendar__tile {
  background-color: transparent; /* Background for individual date tiles */
}

/* Highlight current day with a different background */
.ph-calendar .react-calendar__tile--now {
  background-color: #575757;
  color: white;
}

.ph-adminbtn {
  margin-left: 1.5rem;
  display: block;
  height: 40px;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: white;
  background-color: #ad6f18;
  border-radius: 25px;
}

@media (max-width: 650px) {
  .ph-decor div {
    height: 150px;
  }

  .ph-main-content {
    padding: 2rem 1rem;
  }

  .ph-calendar {
    display: none;
  }

  .ph-data {
    flex-basis: 100%;
  }
}

/*****************************************/
/* REGISTRATION */
/****************************************/

.registerCompanyForm {
  margin-top: 3rem;
  width: 80%;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgba(28, 28, 28, 0.943);
}

.flex-row-container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: baseline;
  justify-content: space-between;
}

.flex-row-container fieldset {
  flex-basis: 45%;
  border: none;
  display: flex;
  flex-direction: column;
  margin: 1rem;
}

.flex-row-container fieldset > input {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  border: none;
  margin: 0.5rem 0;
  background-color: #ffffffcf;
  padding: 1rem;
}

.flex-row-container fieldset > select {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  border: none;
  margin: 0.5rem 0;
  background-color: #ffffffcf;
  padding-left: 1rem;
}

.flex-row-container > legend {
  text-align: center;
  margin: 1rem 0;
}

.flex-row-container fieldset > label {
  align-self: flex-start;
  font-size: 0.9rem;
  margin-top: 1rem;
}

.registerCompanyForm button {
  font-size: 1rem;
  margin: 1rem;
  margin-top: 3rem;
  width: 300px;
  height: 50px;
  border-radius: 10px;
  border: none;
  background-color: rgba(35, 35, 35, 0.839);
  color: rgba(255, 255, 255, 0.864);
  align-self: end;
}

.role-container {
  display: flex;
  gap: 10px;
}
.role-option {
  border: 1px solid #ccc;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  font-size: 0.9rem;
}
input[type="radio"] {
  display: none;
}
input[type="radio"]:checked + label {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

@media (max-width: 850px) {
  .registerCompanyForm {
    width: 95%;
    height: 100%;
  }
}

@media (max-width: 700px) {
  .flex-row-container {
    flex-direction: column;
    align-items: normal;
  }
}


/*****************************************/
/* REGISTRATION */
/****************************************/

.login-form-container > fieldset {
  position: relative;
}

.back-step-button {
  border: 1px solid rgba(0, 0, 0, 0.174);
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: -35%;
  background-color: white;
  font-size: 2.5rem;
  color: rgba(0, 0, 0, 0.576)
}

.privacy-link {
  padding: 1rem;
}

.privacy-link div{
  font-size: 0.7rem;
  color: rgba(0, 0, 0, 0.546)
}

.register-spinner {
  min-height: 250px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.register-spinner img {
  height: 100%;
  width: auto
}

.register-check {
  margin: 1rem auto;
  text-align: center;
  height: 30px;
}

.register-check img {
  height: 100%;
  width: auto;
}


/*****************************************/
/* LOGIN */
/****************************************/

.login-main {
  height: 80vh;
  display: flex;
  justify-content: center;
}

.login-container {
  max-width: 1600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-form {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  max-width: 450px;
  width: 100%; 
  border: 1px solid rgba(0, 0, 0, 0.119);
  border-radius: 10px;
  background-color: white;
}

.login-form-container fieldset {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.119);
}

.login-form-container fieldset input {
  height: 40px;
  border-radius: 8px;
  margin: 1rem 0;
  padding: 1rem;
  font-size: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.188);
  font-weight: 600;
  width: 100%;
}

.lfc-pwd-container {
  position: relative;
}

#password-toggle-button {
  position: absolute;
  right: 10px;
  background-color: transparent;
  border: none;
  color: rgba(0, 0, 0, 0.577);
  font-size: 1rem;
}

.login-form-container fieldset input::placeholder {
  font-weight: 550;
  color: rgba(0, 0, 0, 0.474);

}

.login-form-container fieldset button {
  height: 40px;
  border-radius: 8px;
  margin: 1rem 0;
  font-size: 1.2rem;
  border: 1px solid rgba(0, 0, 0, 0.188);
  background-color: rgba(67, 81, 180, 255);
  color: rgba(255, 255, 255, 0.959)
}

.reset-link-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.reset-link {
  text-decoration: none;
  font-size: 0.9rem;
  color: rgba(67, 81, 180, 255);
}

.reset-link:hover {
  text-decoration: underline;
}

.lfc-btm {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.register-link {
  text-decoration: none;
  color: rgba(67, 81, 180, 255);
  border: 1px solid rgba(67, 81, 180, 255);
  height: 40px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.register-link:hover {
  background-color: rgba(67, 81, 180, 255);
  color: rgba(255, 255, 255, 0.938);
}

@media (max-width: 500px) {
  .login-container {
    width: 90%;
    margin: auto 0;
    justify-content: start
  }
}



/*****************************************/
/* LEAVE MANAGEMENT (ADMIN) */
/****************************************/

.leave-application-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1rem;
  margin-top: 1rem;
}

.error-msg {
  margin: 4rem 2rem 1rem 2rem;
  font-size: 1rem;
  text-transform: capitalize;
}


.leave-records {
  flex: 1;
  overflow-y: auto;
  border-right: 1px solid #ccc;
  margin-top: 0.3rem;
  width: 100%;
  overflow: hidden;
}

.leave-record {
  padding: 1rem;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffffd7;
  flex-grow: 1;
}

.lm-data-cont {
  width: 100%;
  display: flex;
}

.lm-cont-l {
  padding: 0 0.5rem;
  width: 70px;
}

.lm-cont-l img {
  height: auto;
  width: 100%;
  border-radius: 50%;
}

.lm-cont-r {
  padding: 0 0.5rem;
}

.lm-cont-r p {
  margin-bottom: 0.5rem;
  display: flex;
}

.lm-cont-r p > strong  {
  display: inline-block;
  width: 100px;
}

.lm-cont-r p > strong  {
  display: inline-block;
  width: 100px;
}

.lm-cont-r p > span {
  display: inline-block;
  width: 70%;
  height: auto;
  word-wrap: break-word;
  word-break: break-all;
}

.user-leave-data {
  text-transform: capitalize;
  background-color: rgba(196, 196, 196, 0.897);
  color: rgb(0, 0, 0);
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.95rem;

}

.lm-cont-r p > span {
  text-transform: capitalize;
}

.lm-btn-cont {
  margin: 1rem;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.approve-button, .reject-button {
  height: 40px;
  width: 45%;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.approve-button {
  background-color: rgb(255, 255, 255);
  border: 1px solid black;
  color: black;
}

.reject-button {
  background-color: rgb(255, 255, 255);
  border: 1px solid black;
  color: black;
}

.approve-button:hover {
  border: none;
  background-color: rgb(27, 83, 143);
  color: white;
}

.reject-button:hover {
  background-color: rgb(182, 58, 58);
  color: white;
  border: none;
}

.lm-attachment {
  display: block;
  color: black;
  text-decoration: none;
  border: 1px solid rgba(0, 0, 0, 0.316);
  max-width: 350px;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin-top: 1rem;
  font-weight: 800;
}

.lm-attachment span {
  margin-right: 1rem;
}

.calendar-container {
  flex: 2;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.react-calendar {
  width: 100%;
}

/* Adjust the font size of the date number if needed */
.react-calendar__tile > abbr {
  font-size: 1.1rem; /* Adjust font size */
}

.react-calendar__tile {
  height: 100px;
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.user-image {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
}

.tooltip {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  pointer-events: none; /* Prevents the tooltip from interfering with mouse events */
}

.leave-indicators {
  display: flex;
  flex-wrap: wrap;
  gap: 2px; /* Adjust gap between images */
}

.public-holiday {
  background-color: rgba(30, 30, 30, 0.141) !important;
  color: rgb(0, 0, 0);
  position: relative;
}

.public-holiday-text {
  display: inline-block;
  cursor: pointer;
  color: rgb(3, 3, 3);
  font-size: 0.8rem;
}

.tooltip {
  position: absolute;
  background: #47b3f7;
  padding: 10px;
  border-radius: 4px;
  z-index: 1000;
  transform: translateX(-70%);
  min-width: 200px;
  min-height: 100px;
}

.tooltip p {
  margin: 0;
}

.no-leave-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.no-leave-container div:first-child {
  font-size: 1.5rem;
  font-weight: 600;
}

.no-leave-container div:last-child {
  margin-top: 2rem;
  font-size: 1.2rem;
}

@media (max-width: 1000px ) {
  .leave-application-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    margin-top: 1rem;
  }

  .leave-records {
    flex: 1;
    overflow-y: auto;
    border: none;
    margin-top: 0.3rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  
  .leave-record {
    padding: 1rem;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffffd7;
  }
  
}

@media (max-width: 720px ) {

  .leave-records {
    margin-top: 0;
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
  }
  
  .leave-record {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffffd7;
    width: 100%;
  }
  
}

/*****************************************/
/* PROFILE */
/****************************************/

.pfp-main {
  padding: 0 2rem;
}

.pfp-hero {
  min-height: 580px;
  background-color: rgba(255, 255, 255, 0.847);
  position: relative;
}

.hero-img {
  height: 280px;
  width: 100%;
}

.hero-img img{
  height: 100%;
  width: 100%;
  object-fit: fill;
}

.userpfp-img {
  height: 200px;
  width: 200px;
  position: absolute;
  top: 23%;
  left: 3%;
}

.userpfp-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 5px solid rgba(255, 255, 255, 0.847);
}

.userpfp-sm-links {
  height: 5rem;
  padding: 1rem;
  display: flex;
  justify-content: end;
}

.sm-links {
  display: flex;
}

.sm-links-img {
  height: 32px;
  width: 32px;
  margin-right: 0.2rem;
}

.sm-links-img img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.sm-links-img:hover {
  background-color: #e9e9e9ac;
  cursor: pointer;
}

.userpfp-pd {
  display: flex;
  padding: 1rem 3.5rem;
}

.userpfp-pd-user {
  flex-basis: 60%;
}

.userpfp-pd-user div {
  margin-bottom: 0.2rem;
}

.userpfp-name {
  font-size: 1.8rem;
  font-weight: 700;
}

.userpfp-role {
  font-size: 1.2rem;
}

.userpfp-innercont-btn {
  margin: 1rem 0;
}

.userpfp-location {
  color: rgba(0, 0, 0, 0.544);
  margin-right: 1.5rem;
}

.userpfp-link {
  color: rgba(67, 114, 196, 0.974);
  font-weight: 600;
}

.userpfp-connect-innercont {
  margin: 1rem 0;
}

.userpfp-imgconnect-innercont {
  display: flex;
  flex-wrap: wrap;
}

.userpfp-connect-userimg {
  height: 25px;
  width: 25px;
  margin-right: 0.1rem;
}

.userpfp-connect-userimg img {
  height: 100%;
  width: 100%;
}

.userpfp-innercont-btn {
  width: 100%;
  display: flex;
}

.userpfp-innercont-btn > div {
  margin-right: 1rem;
  height: 30px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.779);
  border-radius: 20px;
}

.userpfp-action-btn {
  background-color: rgb(52, 89, 154);
  width: 150px;
  font-weight: 500;
  color: white;
}

.userpfp-pd-compedu {
  flex-basis: 40%;
  padding: 1rem 1rem 1rem 5rem;
}

.userpfp-compedu-cont {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.userpfp-compedu-img {
  height:50px;
  width: 50px;
  margin-right: 0.7rem;
}

.userpfp-compedu-img img {
  height:100%;
  width: 100%;
  object-fit: contain;
}

.userpfp-compedu-name {
  width: 200px;
  font-weight: 700;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.728);
}

.edit-pfp-btn {
  padding: 0.5rem;
  position: absolute;
  top: 91%;
  right: 3%;
  height: 35px;
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid rgba(0, 0, 0, 0.449);
  font-size: 0.95rem;
  text-decoration: none;
}

.edit-pfp-btn span img {
 scale: 0.8;
}

.userpfp-main {
  margin-top: 2.5rem;
  min-height: 500px;
  background-color: rgba(255, 255, 255, 0.847);
}

.userpfp-act-links {
  height: 60px;
  display: flex;
  padding: 3rem;
  align-items: center;
  justify-content: center;
}

.userpfp-act-links-btn {
  border: 1px solid rgba(71, 71, 71, 0.97);
  height: 40px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.userpfp-links-main {
  padding: 1rem 3rem 5rem 3rem;
  min-height: 300px;
}

.userpfp-about1 {
  width: 100%;
  min-height: 300px;
  text-align: justify;
  white-space: pre-wrap;
}

#update-pfp-btn {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.964);
  width: 250px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #075492;
  z-index: 1111;
  font-weight: 600;
  border-radius: 30px;
  margin-top: 2rem;
}

@media  (max-width: 1080px) {
  .pfp-main {
    padding: 0;
  }

  .userpfp-pd {
    padding: 1rem;
  }

@media (max-width: 750px) {
  .userpfp-pd {
    flex-direction: column;
  }

  .userpfp-pd-user {
    flex-basis: 100%;
    padding-bottom: 1rem;
  }

  .userpfp-pd-compedu {
    flex-basis: 100%;
    display: flex;
    padding: 1rem 0;
  }
}

@media (max-width: 600px) {

  .userpfp-pd-compedu {
    flex-direction: column;
  }

  .userpfp-links-main {
    padding: 1rem;
  }
}

@media (max-width: 450px) {
  .edit-pfp-btn {
    padding: 0.5rem;
    position: absolute;
    top: 101%;
    right: 0;
    left: 3%;
    height: 30px;
  }

  .sm-links {
    display: none;
  }
}
}


/*****************************************/
/* EDIT-PROFILE */
/****************************************/

.ep-main {
  display: flex;
  justify-content: center;
  max-width: 1500px;
}

.ep-container {
  width: 100%;
  padding: 2rem;
  display: grid;
  grid-template-columns: 0.7fr 1fr;
}

.ep-left-item {
  border: 1px solid rgba(26, 26, 26, 0.225);
  height: 300px;
  padding: 2rem;
}

.ep-li-title {
  font-weight: 600;
}

.ep-li-desc {
  padding: 1rem 2rem 1rem 0;
  text-align: justify;
  font-size: 0.9rem;
}

.ep-li-link {
  height: 32px;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}

.ep-li-link span:first-child{
  margin-right: 0.5rem;
}

.ep-right-wrapper {
  padding: 1rem;
}

.ep-form > label {
  display: block;
  margin: 0.8rem 0;
}

.ep-form > input {
  border: none;
  height: 35px;
  width: 100%;
  max-width: 600px;
  padding: 0.3rem;
  font-size: 0.9rem;
  margin-bottom: 0.7rem;
  border: 0.2px solid rgba(0, 0, 0, 0.268);
}

.ep-form > select {
  border: none;
  height: 35px;
  width: 100%;
  max-width: 600px;
  padding: 0.3rem;
  font-size: 0.9rem;
  margin-bottom: 0.7rem;
  outline: 1px solid rgba(21, 21, 21, 0.292);
}

.ep-btn {
  height: 40px;
  width: 200px;
}

.ep-updateimg {
  margin: 1.5rem 0;
  border: 1px solid rgba(0, 0, 0, 0.183);
  height: 150px;
  width: 100%;
  max-width: 600px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.ep-updateimg div {
  width: 100%;
  height: 50%;
}

.ep-updateimg-img {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.ep-updateimg-img img {
  height: 100%;
  width: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;
}

.ep-updateimg-btn {
  margin-top: 1rem;
  min-height: 40px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.816);
  font-size: 0.9rem;
  font-weight: 600;
}

.ep-updateimg-btn:hover {
  background-color: rgba(127, 127, 127, 0.088);
  cursor: pointer;
}

.ep-dd-title {
  height: 40px;
  width: 95%;
  max-width: 600px;
  padding: 0.3rem; 
  font-size: 0.9rem;
  margin-bottom: 0.7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid rgba(0, 0, 0, 0.24);
  margin: 0;
}

.ep-dd-title:hover {
 cursor: pointer;
}

.ep-rm-btn {
  height: 35px;
  position: relative;
  left: 55px;
  font-size: 1.5rem;
  border: none;
  font-weight: 700;
  width: 30px;
}

.ep-rm-btn:hover, .ep-add-btn:hover {
  cursor: pointer;
}

.ep-add-btn {
  font-size: 1.5rem;
  border: none;
  font-weight: 700;
  border-radius: 100%;
  width: 30px;
  border: 1px solid black;
}

.ep-dd-header {
  display: flex;
  align-items: center;
  margin: 1rem 0;
}

.ep-dd-header > label {
  width: 80px;
}

.ep-dropdown {
  min-height: 200px;
  width: 100%;
  max-width: 600px;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  padding: 1rem 0.5rem;
}

.ep-dropdown-items {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  margin: 0.3rem 0;
}

.ep-dropdown-item {
  margin-right: 2rem;
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
  margin: 0.3rem 0;
}

.ep-dropdown-items label {
  flex-basis: 110px;
}

.ep-dropdown-item label {
  margin-bottom: 0.3rem;
}


.ep-dropdown-item input {
  height: 30px;
  width: 250px;
  background-color: rgba(244, 244, 244, 0.9);
  border: none;
  padding: 0.5rem;
}

.ep-dropdown-items input {
  height: 30px;
  width: 250px;
  background-color: rgba(244, 244, 244, 0.9);
  border: none;
  padding: 0.5rem;
}

.ep-dd-items-time {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.ep-dropdown-items input[type="checkbox"] {
  height: 20px;
  width: 5%;
}

.ep-dropdown-checkbox {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  margin: 0.5rem 0;
}

.ep-dropdown-checkbox label {
  flex-basis: 60px;
}

.ep-textarea {
  width: 100%; 
  min-height: 100px; 
  padding: 8px; 
  border: 1px solid #ccc; 
  border-radius: 4px; 
  box-sizing: border-box; 
  resize: vertical;
}

.ep-about-textarea {
  width: 100%;
  max-width: 600px;
  min-height: 150px;
  padding: 8px; 
  border: none;
  border-radius: 4px; 
  box-sizing: border-box; 
  resize: vertical; 
  border: 0.2px solid rgba(0, 0, 0, 0.268);
}

.ep-save-btn {
  margin: 1rem auto;
  width: 300px;
  height: 30px;
}

.ep-form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
}

.eb-fg-item {
  margin-bottom: 1rem;
  display: flex;
}

.eb-fg-item span {
  height: 40px;
  width: 45px;
  margin-right: 1rem;
}

.eb-fg-item span > img {
  height: 100%;
  width: 100%;
}

.eb-fg-item input {
  width: 100%;
  padding: 0.5rem;
  border: none;
}

.pwd-form {
  width: 100%;
  max-width: 500px;
}

.pwd-form label {
  font-size: 0.9rem;
}

.pwd-form input {
  font-size: 0.9rem;
  margin: 0.5rem 0;
}

.modal-overlay {
  position: fixed;
  top: 25%;
  left: 0;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  margin: 1rem;
  min-width: 300px;
  max-width: 700px;
  width: 60%;
  min-height: 600px;
  background: rgb(255, 255, 255);
  padding: 3rem;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.crop-container {
  max-width: 100%;      
  min-height: 400px; 
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-easy-crop {
  width: 100%;   /* Ensure cropper takes full width of the container */
  height: 250px;  /* Ensure cropper takes full height of the container */
  object-fit: cover;  /* Make sure the image fits within the cropper */
}

.reactEasyCrop_CropArea {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 5px solid rgb(8, 124, 17) !important;
  box-sizing: border-box;
  box-shadow: 0 0 0 9999em;
  color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  border-radius: 50% ;
  height: 280px !important;
  width: 280px !important;
}

.reactEasyCrop_Container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  user-select: none;
  touch-action: none;
  cursor: move;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100% !important;
  width: 100% !important;
}

.zoom-slider-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  height: 20px !important;
  position: absolute;
  bottom: 5%
}

.zoom-slider-container > input {
  width: 50% !important;
  -webkit-appearance: none;  /* Remove default styles */
  appearance: none;
  height: 8px;  /* Change the height of the slider */
  background-color: #ffffff;  /* Set the track background color */
  border-radius: 5px;  /* Rounded corners for the track */
  outline: none;  /* Remove outline on focus */
  transition: background-color 0.2s ease;  /* Smooth transition for color change */
}

/* Webkit browsers (Chrome, Safari, Edge) */
.zoom-slider-container > input::-webkit-slider-thumb {
  -webkit-appearance: none; /* Remove default styles */
  appearance: none;
  height: 20px; /* Height of the thumb */
  width: 20px; /* Width of the thumb */
  border-radius: 50%; /* Make it round */
  background-color: rgb(8, 124, 17); /* Change to your desired color */
  border: 2px solid #fff; /* Optional: Add a border */
  cursor: pointer; /* Change cursor to pointer */
  transition: background-color 0.2s ease; /* Smooth transition for color change */
}

.modal-actions {
  max-width: 400px;
  width: 55%;
  display: flex;
  align-items: center;
  justify-content: space-around;

}

.ep-cancel-btn {
  border: 2px solid rgba(12, 12, 12, 0.538);
  background-color: transparent;
  height: 45px;
  width: 120px;
  border-radius: 25px;
  font-size: 1rem;
}

.ep-cancel-btn:hover {
  cursor: pointer;
}

.ep-update-btn {
  height: 45px;
  width: 200px;
  border-radius: 25px;
  border: none;
  background-color: rgb(21, 139, 31);
  color: white;
  font-size: 1.1rem;
}

.ep-update-btn:hover {
  cursor: pointer;
}

.upload-feedback {
  margin-top: 2rem;
  width: 100%;
  font-size: 0.9rem;
}

@media (max-width: 1067px) {
  .modal-content div input {
    width: 100%;
  }
}


@media (max-width: 768px) {
  .ep-container, .ep-dd-items-time {
    grid-template-columns: 1fr; /* Single column layout on smaller screens */
  }

  .ep-container {
    padding: 0;
  }

  .ep-container {
    grid-template-rows: 320px auto;
  }
  

}

@media (max-width: 420px) {
  .ep-left-item {
    padding: 2rem 2rem 2rem 1rem;
  }

  .ep-right-wrapper {
    padding: 2rem 2rem 2rem 1rem;
  }

  .ep-container {
    display: block;
  }
}



/*****************************************/
/* EDIT-COMPANY PROFILE */
/****************************************/

.ecp-updateimg {
  margin: 1.5rem 0;
  border: 1px solid rgba(0, 0, 0, 0.183);
  height: 280px;
  width: 100%;
  max-width: 600px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.ecp-updateimg-img {
  height: 70%;
  width: 100%;
  display: flex;
  align-items: center;
}

.ecp-updateimg-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.ecp-modal-overlay {
  position: fixed;
  top: 5%;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.ecp-modal-content {
  min-width: 400px;
  max-width: 50%; 
  min-height: 380px;
  background: rgb(245, 245, 245);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ecp-modal-content div {
  height: 90px;
  font-size: 1.2rem;
  font-weight: 700;
  padding: 2rem;
}

.ecp-updateicon {
  margin: 1.5rem 0;
  border: 1px solid rgba(0, 0, 0, 0.183);
  height: 200px;
  width: 100%;
  max-width: 600px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.ecp-updateimg-icon {
  height: 65%;
  width: 100%;
  display: flex;
  align-items: center;
}

.ecp-updateimg-icon img {
  height: 100%;
  width: 100%;
  object-fit: fill;
}

/*****************************************/
/* GENERAL SETTINGS- COMPANY */
/****************************************/

.leave-yearsinservice-wrap {
  border: 1px solid rgba(174, 174, 174, 0.644);
  padding: 1rem;
}

.addLIbtn {
  border: none;
  cursor: pointer;
  display: flex;
}

.addLIbtn span:first-child {
  font-size: 2rem;
  margin-right: 0.5rem;
}

.addLIbtn span:last-child {
  font-size: 1rem;
  font-weight: 600;
  padding-top: 0.5rem;
}

.leave-increment {
  margin: 1rem 0;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: rgb(228, 228, 228);
  position: relative
}

.li-btn {
  font-size: 2rem;
  position: absolute;
  right: 3%;
}

.li-btn:hover {
  cursor: pointer;
}


.leave-increment label {
  margin: 0.5rem 0;
}

.leave-increment input {
  min-height: 35px;
  border: none;
  padding: 0.5rem;
  max-width: 300px;
}

.leave-increment-active-wrapper {
  display: flex;
  align-items: center;
  width: 100px;
}

.leave-increment-active-wrapper input {
  margin-right: 0.5rem;
}

.leave-increment-active-wrapper label {
  font-size: 0.9rem;
  font-weight: 550;
}

.gs-item-container {
  margin: 2rem 0;
}

.gs-item-desc {
  text-align: justify;
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
  font-weight: 450;
  color: rgba(0, 0, 0, 0.421);
}

.gs-item-desc span:first-child {
  font-size: 1.5rem;
}

.gs-item-desc span:last-child {
  background-color: #f7f7f79a;
  padding: 1rem;
}

.gs-item-innercontainer {
  margin: 1rem 0;
}

.gs-item-input-container {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
}

.gs-item-input-container label{
  margin-bottom: 0.5rem;
}

.gs-item-input-container input{
  height: 35px;
  border: none;
  max-width: 330px;
  padding: 0.5rem;
}

.gs-item-input-container select {
  height: 35px;
  border: none;
  max-width: 330px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 34px;
  margin-bottom: 2rem;
}

.switch-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-label {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: #ccc;
  border-radius: 34px;
  cursor: pointer;
  transition: background-color 0.4s;
}

.switch-button {
  position: absolute;
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.4s;
}

.switch-input:checked + .switch-label {
  background-color: #4caf50;
}

.switch-input:checked + .switch-label .switch-button {
  transform: translateX(65px);
}

.switch-message {
  width: 200px;
  display: block;
  position: absolute;
  top: 15%;
  left: 120%
}

/*****************************************/
/* COMPANY SETTINGS (ATTENDANCE) */
/****************************************/

.maps-btn {
  margin-bottom: 1rem
}

.maps-name {
  height: 40px;
  max-width: 400px;
  width: 100%;
  margin-bottom: 1rem;
  padding: 1rem 0.5rem;
  border: none;
  font-size: 1.1rem;
  font-weight: 600;
  background-color: transparent;
}

.maps-search-container {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative
}

.maps-search-container input {
  height: 30px;
  width: 80%;
  padding: 1rem;
  border: 0.5px solid rgba(0, 0, 0, 0.502);
}

.maps-search-container button {
  height: 34px;
  width: 50px;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 5%;
  right: 0%;
  border: 0.5px solid rgba(0, 0, 0, 0.502);
}

.maps-hidden {
  display: none;
}

.maps-radius label {
  margin-top: 1rem;
}

.maps-radius input {
  margin-top: 1rem;
  margin-right: 1rem;
}

.maps-radius button {
  margin-top: 1rem;
}

.work-hours-row {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.work-hours-input {
  border: none;
  background-color: transparent;
  width: 100px;
  font-size: 1rem;
  font-weight: 600;
}

.wh-title {
  margin-bottom: 1rem;
}

.wh-title input[type="checkbox"] {
  margin-right: 1rem;
}


.wh-time {
  display: flex;
  align-items: center;}

.wh-time div {
  font-size: 0.85rem;
  font-weight: 600;
  margin-bottom: 0.3rem;
  margin-right: 1rem;
}

.wh-time input {
  min-width: 175px;
  width: 100%;
}

@media (max-width: 420px) {
  .wh-time input {
    min-width: 150px
  }
}

/*****************************************/
/* LEAVE APPROVED */
/****************************************/

.black-background {
  background-color: rgba(0, 0, 0, 0.82);
  height: auto;
}

.LeaveApproved-main {
  height: auto;
  padding-bottom: 5rem;
}

.redirect-header {
  padding: 2rem;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.redirect-btn {
  margin: 1.5rem 0;
  height: 40px;
  max-width: 600px;
  background-color: rgba(255, 255, 255, 0.801);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  font-weight: 600;
  border-radius: 10px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.826);
}

.redirect-main {
  background-color: rgba(0, 0, 0, 0.294);
  min-height: 300px;
  color: rgba(255, 255, 255, 0.828);
  padding: 3rem;
}

.redirect-main div {
  margin: 1rem 0;
}

.redirect-msg {
  margin-bottom: 1rem;
}

.redirect-img {
  height: 500px;
  width: 100%;
}

.redirect-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}




/*****************************************/
/* LOGO */
/****************************************/

.logo-la {
  font-size: 2.5rem;
  font-weight: 400;
  color: white;
  height: 50px;
  width: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  font-size: 1.5rem;
  font-weight: 400;
  color: white;
  height: 45px;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.736);
}


/*****************************************/
/* WIP */
/****************************************/

.wip-container {
  margin: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wip-header {
  margin: 1rem;
  font-size: 2.5rem;
  color: rgba(215, 145, 6, 0.878);
  font-weight: 600;
}

.attribute {
  font-size: 0.8rem;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.708);
}


/*****************************************/
/* ATTENDANCE DASHBOARD */
/****************************************/

.attendance-dashboard {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 1rem;
}

.attendance-dashboard > div {
  padding: 1rem;
}

.ad-left {
  background-color: white;
  display: flex;
  align-items: start;
  justify-content: center;
}

.adl-component {
  height: 350px;
  max-width: 500px;
  width: 100%;
}

.ad-right {
  background-color: white;
}

.ar-time {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.ar-item-cont {
  padding: 1rem;
  background-color: rgba(245, 245, 245, 0.297);
  min-height: 250px;
}

.ar-items {
  max-width: 380px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  margin-top: 0.5rem;
}

.break-duration {
  margin-top: 0.5rem;
  font-size: 0.9rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.482);
  max-width: 450px;
  height: 35px;
  display: flex;
  align-items: center;
}

.break-duration span:first-child {
  font-size: 1.5rem;
  margin-right: 1rem;
}

.ar-items-header {
  display: inline-block;
  font-size: 0.9rem;
  font-weight: 500;
  min-width: 80px;
}

.ar-td {
  margin-top: 1.3rem;
  font-size: 0.9rem;
}

.ar-td div:last-child {
  margin-top: 0.3rem;
  display: flex;
  align-items: center;
}

.ar-td div:last-child span:first-child {
  font-size: 1.5rem;
  margin-right: 1rem;
}

@media (max-width: 500px) {
  .attendance-dashboard {
    display: flex;
    flex-direction: column;
  }
}

/*****************************************/
/* ATTENDANCE COMPONENT */
/****************************************/

#gsm-att {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
}

.ac-main {
  min-width: 250px;
  width: 100%;
  min-height: 350px;
  height: 100%;
  background-color: white;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr;
}

.attendance-component-header {
  order: 2;
  background-image: url('/public/images/decor.jpg');
  background-size: cover;
  background-position: center;
}

.ach-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.1rem;
}

.attendance-component {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}

.attendance-btn {
  border: 0.5px solid rgba(0, 0, 0, 0.425);
  height : 100px;
  width: 42%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ab-clockin:hover {
  box-shadow: 0.5px 0.5px 5px rgb(0, 0, 0); 
  transform: translateY(-5px);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.ab-clockout {
  background-color: rgb(150, 244, 150);
  color: green;
} 

.ab-clockout:hover {
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.135); 
  transform: translateY(-5px);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.ab-break {
  background-color: rgb(249, 210, 70);
  color: rgb(9, 149, 9);
}

.attendance-btn div {
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 0.95rem;
}

.attendance-btn div:first-child {
  font-size: 1.8rem;
}

.attendance-component-header {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.85rem;
  font-weight: 500;
}


/*****************************************/
/* ATTENDANCE MODAL */
/****************************************/

.attendance-modal-content {
  height: 90vh;
  width: 80vw;
  min-width: 300px;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow-y: auto;
}

.inner-modal {
  width: 90%;
  height: 95%;
}

.im-error-msg {
  min-height: 100px;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.im-error-msg div {
  border: 1px solid rgb(0, 0, 0);
  min-height: 50px;
  height: auto;
  width: 80%;
  color: rgb(8, 8, 8);
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.im-form {
  padding: 1rem 2rem;
}

.imf-reason {
  display: flex;
  flex-direction: column;
}

.video-cvs-cont {
  width: 100%;
  height: 200px;
  position: relative;
}

.video-cvs {
  background-color: black;
  width: 100%;
  height: 200px;
}

.camera-act-cont {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  background-color: transparent;
  height: 80px;
  width: 150px;
  top: 80%;  /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Offset to truly center */
}

.camera-snap {
  border: 1px solid rgb(252, 252, 252);
  height: 60px;
  width: 60px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  background-color: white;
}

.camera-switch {
  border: 1px solid rgb(252, 252, 252);
  height: 60px;
  width: 60px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  background-color: white;
}

.handleOpenCamerabtn {
  margin: 1rem auto;
  font-size: 2.2rem;
}

.handleOpenCamerabtn div {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 1px solid rgba(0, 0, 0, 0.195);
  width: 60px;
  height: 60px;
}

.handleOpenCamerabtn div:hover {
  color: rgb(255, 255, 255);
  border: 0.5px solid rgb(7, 10, 83);
  background-color: rgb(7, 10, 83);
  cursor: pointer;
}


.im-guidelines {
  text-align: justify;
  font-size: 0.8rem;
  margin-bottom: 1rem;
}

.imf-reason textarea {
  height: 70px;
  width: 100%; 
  resize: vertical;
  overflow: auto;
  padding: 0.3rem;
}

.imf-ci-btn {
  border: 1px solid black;
  height: 45px;
  margin: 1rem 0;
  max-width: 180px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imf-ci-btn:hover {
  cursor: pointer;
}

.imf-closemodal {
  position: absolute;
  top: 2%;
  right: 3%;
  font-size: 1.5rem;
}

.imf-retake {
  display: inline-block;
  margin: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
}

@media (max-width: 600px) {
  .imf-ci-btn {
    max-width: 100%;
  }

  .attendance-modal-content {
    height: auto;
    min-height: 80%;
    width: 95%;
  }
  
  .inner-modal {
    min-height: 100%;
    height: 100%;
  }

  .im-form {
    padding: 1rem;
  }

  .im-error-msg {
    min-height: 50px;
    height: 20%;
    justify-content: start;
    padding: 0 1rem;
  }
  
  .im-error-msg div {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.334);
    padding: 0;
  }
  
}

/*****************************************/
/* EDITOR  */
/****************************************/

.editor-layout {
  height: 100%;
  padding: 1rem;
}

/* Targeting the main editable area of the editor */
.ProseMirror {
  min-height: 100vh;
  padding: 2.5rem;
  border-radius: 10px;
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  background-color: white;
  color: #333;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  overflow: auto;
}

.ProseMirror:focus, .ProseMirror:active {
  border: none;
  outline: none;
}


/* Styling headings */
.ProseMirror h1 {
  font-size: 2rem;
  margin: 0.5em 0;
  font-weight: bold;
  color: #333;
}

.ProseMirror h2 {
  font-size: 1.75rem;
  margin: 0.5em 0;
  font-weight: bold;
  color: #444;
}

.ProseMirror h3 {
  font-size: 1.5rem;
  margin: 0.5em 0;
  font-weight: bold;
  color: #555;
}

/* Styling blockquotes */
.ProseMirror blockquote {
  padding: 0.5em 1em;
  margin: 1em 0;
  border-left: 4px solid #ddd;
  color: #666;
  font-style: italic;
}

/* Styling lists */
.ProseMirror ul, .ProseMirror ol {
  padding-left: 1.5em;
}

/* Styling code blocks */
.ProseMirror pre {
  background: #f4f4f4;
  padding: 1em;
  border-radius: 5px;
  overflow-x: auto;
}

.ProseMirror code {
  background: #f4f4f4;
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-family: monospace;
}


.control-group {
  margin-bottom: 1rem;
}

.button-group {
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 1rem;
  background-color: white;
  /* Enhanced box-shadow for depth */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.08);
  font-weight: 400;
  width: auto;
}

.button-group > div {
  font-size: 1.2rem;
  height: 100%;
  margin-right: 1rem;
}

.menu-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 130px;
  border-right: 2px solid rgba(84, 84, 84, 0.35);
  position: relative;
}

.menu-cont > div {
  margin-right: 0.2rem;
  padding: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-editor-dropdown {
  position: relative;
}

.text-editor-dropdown-toggle {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: start;
  border-right: 2px solid rgba(84, 84, 84, 0.35);
  min-width: 100px; 
}

.text-editor-dropdown-toggle > span  {
  margin-right: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-editor-dropdown-menu {
  position: absolute;
  top: 140%;
  left: -12%;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  min-width: 160px; 
}

.text-editor-dropdown-item {
  padding: 8px 12px;
  cursor: pointer;
}

.text-editor-dropdown-item:hover {
  background-color: #ddd;
}

.text-editor-is-active {
  background-color: #e0e0e0;
}

/* Add this CSS to your stylesheet or style block */
.is-active {
  background-color: #d3d3d3; /* Set your preferred background color */
  border-radius: 4px; /* Optional: to make it look nice */
}

/* Table styling */
.ProseMirror table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 16px;
  table-layout: fixed;
}

.ProseMirror th,
.ProseMirror td {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: left;
}

.ProseMirror th {
  font-weight: bold;
}

/* Customizable border radius for table */
.ProseMirror table {
  border-radius: 6px;
}

.ProseMirror .selectedCell {
  outline: 0.5px solid #000000a2;
}

/* Optional - Make table responsive on smaller screens */
@media (max-width: 600px) {
  .ProseMirror table {
    width: 100%;
  }
  
  .ProseMirror th, .ProseMirror td {
    padding: 4px;
  }
}

.table-size-selector {
  display: inline-block;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
  width: max-content;
}

.row {
  display: flex;
}

.cell {
  width: 20px;
  height: 20px;
  border: 1px solid #ddd;
  background-color: #fff;
  cursor: pointer;
}

.cell.selected {
  background-color: #007bff;
}

.size-label {
  margin-top: 8px;
  text-align: center;
  font-size: 14px;
  color: #555;
}

.table-selector-popup {
  position: absolute;
  top: 120%;
  left: -60%;
  z-index: 10;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.table-controls {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.4rem;
}

.table-controls > div {
  margin-right: 1rem;
  padding: 0.2rem;
}

.table-controls > div:hover {
  background-color: #d3d3d3;
  cursor: pointer;
}

.sub-menu {
  margin-top: 0.5rem;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.08);
  font-weight: 400;
}  

mark {
  background-color: #FAF594;
  border-radius: 0.4rem;
  box-decoration-break: clone;
  padding: 0.1rem 0.3rem;
}

.highlightis-active {
  background-color: #FAF594;
}

 /* Task list specific styles */
 ul[data-type="taskList"] {
  list-style: none;
  margin-left: 0;
  padding: 0;

  li {
    align-items: flex-start;
    display: flex;

    > label {
      flex: 0 0 auto;
      margin-right: 0.5rem;
      user-select: none;
    }

    > div {
      flex: 1 1 auto;
    }
  }

  input[type="checkbox"] {
    cursor: pointer;
  }

}

li[data-checked="true"] {
  div {
    text-decoration: line-through;
    color: rgba(0, 0, 0, 0.398);
    font-weight: 500;
  }
}

.mc-last {
  border: none;
}

.ProseMirror {

  > * + * {
    margin-top: 0.75em;
  }

  [data-id] {
    border: 3px solid #0D0D0D;
    border-radius: 0.5rem;
    margin: 1rem 0;
    position: relative;
    margin-top: 1.5rem;
    padding: 2rem 1rem 1rem;

    &::before {
      content: attr(data-id);
      background-color: #0D0D0D;
      font-size: 0.6rem;
      letter-spacing: 1px;
      font-weight: bold;
      text-transform: uppercase;
      color: #fff;
      position: absolute;
      top: 0;
      padding: 0.25rem 0.75rem;
      border-radius: 0 0 0.5rem 0.5rem;
    }
  }
}

.drag-handle {
  cursor: grab;
  height: 4rem;
  width: 4rem;
}

.bubble-menu {
  box-shadow: 0.1px 0.1px 2px rgb(160, 160, 160);
  border-radius: 5px;
  z-index: 9999;
  min-width: 250px;
  height: 38px;
  background-color: white;
  align-items: center;
  justify-content: center;
}

.bubble-menu > div {
  width: 30px;
  margin-right: 0.3rem;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mc-color {
  border: none !important;
  width: auto;
  margin: 0;
  padding: 0;
}

/* Dropdown container styling */
.color-dropdown {
  min-width: 100px;
  padding: 4px;
  font-family: Arial, sans-serif;
  border: 1px solid #ccc;
  border-radius: 4px;
  appearance: none; /* Remove default dropdown arrow */
  background-color: white;
  cursor: pointer;
  margin-left: 2px;
}

/* Styling each option to show the color */
.color-dropdown option.color-black { background-color: #000000; color: white }
.color-dropdown option.color-purple { background-color: #958DF1; }
.color-dropdown option.color-red { background-color: #F98181; }
.color-dropdown option.color-orange { background-color: #FBBC88; }
.color-dropdown option.color-yellow { background-color: #FAF594; }
.color-dropdown option.color-blue { background-color: #70CFF8; }
.color-dropdown option.color-teal { background-color: #94FADB; }
.color-dropdown option.color-green { background-color: #B9F18D; }

/* Optional: Styling for the color picker */
.color-picker {
  width: 30px;
  height: 30px;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  border-radius: 4px;
}

input[type="color" i] {
  cursor: default;
  box-sizing: border-box;
  background-color: none;
  border: none;
  padding: 0;
  width: 30px;
  height: 30px;
}

.ProseMirror .column-block {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  gap: 24px;
  padding: 8px 0;
}

.ProseMirror .column {
  overflow: auto;
  border: 1px gray dashed;
  border-radius: 8px;
  padding: 8px;
  margin: -8px;
}

.menu-cont > div:hover {
  cursor: pointer;
  scale: 1.2
}

/*****************************************/
/* LEAVE SUMMARY COMPONENT */
/****************************************/

/* Table container with fixed height and scrolling */
.table-container {
  overflow: hidden; /* Hide the scrollbar by default */
  height: 350px; /* Set your desired height for the table container */
  background-color: #fff;
  position: relative; /* Ensures the hover effect works correctly */
}

/* Show scrollbar only when hovering over the table */
.table-container:hover {
  overflow: scroll; /* Enable scrollbar on hover */
}
/* Style the scrollbar */
.table-container::-webkit-scrollbar {
  width: 8px; 
  height: 10px;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #888; /* Thumb color */
  border-radius: 4px; /* Rounded corners */
}

.table-container::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Thumb color on hover */
}

.table-container::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Track color */
  border-radius: 4px; /* Rounded corners */
}

/* General table styling */
.responsive-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9rem;
  text-align: left;
  border: 1px solid #ddd;
  min-width: 600px; /* Enforces horizontal scrolling for small screens */
}

/* Header styles */
.responsive-table thead th {
  background-color: #f4f4f4;
  padding: 10px;
  border: 1px solid #ddd;
  font-weight: bold;
  white-space: nowrap; /* Prevent header text wrapping */
  position: sticky; /* Keeps header visible during vertical scroll */
  top: 0;
  z-index: 1; 
  text-align: center;
}

.table-usedleaves {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.table-usedleaves > div > span:first-child {
  display: inline-block;
  font-size: 0.8rem;
  font-weight: 600;
  text-align: start;
  width: 130px;
  margin-right: 0.5rem;
}

.table-teh {
  text-align: left !important;
  padding-left: 1.5rem !important;
}

.topemptyheader {
  border-bottom: 1px solid #f4f4f4 !important;
  text-align: left !important;
}

.emptyheader1 {
  border-top: 1px solid #f4f4f4 !important;
}

.emptyheader2 {
  border-top: 1px solid #f4f4f4 !important;
}

/* Body rows */
.responsive-table tbody td {
  padding: 10px;
  white-space: nowrap; /* Prevent row text wrapping */
  border: 0.1px solid rgba(0, 0, 0, 0.104);
  border-top: none;
  text-align: center;
  height: 110px;
}

.ls-header-name {
  display: flex;
  align-items: center;
  min-width: 230px;
  text-align: start !important;
} 

.ls-header-name > span {
  margin-right: 0.5rem;
  min-width: 35px;
  white-space: normal; /* Allows text to wrap */
  overflow: hidden; /* Prevents content from overflowing */
  word-break: break-all
}

/* Avatar styling */
.user-avatar {
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.ls-minwidth {
  min-width: 100px;
  text-decoration: none;
  font-weight: 300;
}

/* Responsive behavior */
@media (max-width: 768px) {
  .responsive-table {
      font-size: 0.8rem;
  }
}

@media (min-width: 950px) {
  .ls-component-cont {
    grid-column: span 2;
  }

  .adm-grid-main {
    grid-auto-flow: dense;
  }

}

.ls-table-export {
  display: flex;
  flex-direction: row;
}

.export-exl-button,.export-pdf-btn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  scale: 0.8;
}

.export-exl-button:hover, .export-pdf-btn:hover {
  background-color: #8181813e;
  cursor: pointer;
}

/*****************************************/
/* LAYOUT */
/****************************************/

/* layout-header */

.layout-header {
  height: 50px;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 680px ){
  .layout-header {
    flex-direction: column;
    align-items: start;
    justify-content: start;
    height: 80px;
  }

  .welcome-user {
    margin-bottom: 1rem;
  }
}

/* layout-dropdown.css */

.lh-childtwo {
  display: flex;
}

.lh-childtwo > div {
  margin-left: 1rem;
}

.exit-resize-button {
  background-color: #421ab2;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center; /* Ensures the spans are spaced side-by-side */
  position: relative; /* Helps manage layout adjustments */
  width: 165px;
  height: 40px;
}

.exit-resize-button > span {
  display: flex;
  align-items: center;
  justify-content: center; /* Ensures the spans are spaced side-by-side */
  margin-right: 0.3rem;
}

.exit-resize-button > span:first-child {
  font-size: 1.2rem;
}

.layout-dropdown {
  position: relative;
  display: inline-block;
}

/* Add rotation animation */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.layout-dropdown .dropdown-toggle {
  background-color: #28a798;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center; /* Ensures the spans are spaced side-by-side */
  position: relative; /* Helps manage layout adjustments */
  width: 165px;
  height: 40px;
}

.layout-dropdown .dropdown-toggle > span {
  display: flex;
  align-items: center;
  justify-content: center; /* Ensures the spans are spaced side-by-side */
  margin-right: 0.3rem;
}

.layout-dropdown .dropdown-toggle > span:first-child {
  font-size: 1.2rem;
}

/* Rotate the SVG on hover */
.layout-dropdown .dropdown-toggle:hover span:first-child svg {
  animation: spin 1s linear infinite;
  font-size: 2rem;
  position: absolute; /* Position it absolutely within the parent */      
  right: 39%; /* Move it to the horizontal center */
  top: 10%; /* Move it to the vertical center */
}


/* Style for the last span (Widget text) */
.layout-dropdown .dropdown-toggle span:last-child {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.1s ease, visibility 0.1s ease; /* Smooth fade effect */
  transition-delay: 0.1s; /* Optional: Delay visibility change for smoother transition */
}

/* On hover, fade out and hide the last child */
.layout-dropdown .dropdown-toggle:hover span:last-child {
  opacity: 0;
  visibility: hidden;
}

/* Active state styles */
.layout-dropdown .dropdown-toggle.active {
  background-color: #f0cc2a; /* Darker green for active */
  color: #262626; /* Lighter text */
}

.layout-dropdown .dropdown-toggle:focus {
  outline: none;
}

.layout-dropdown .dropdown-menu {
  position: absolute;
  top: 120%;
  right: 0;
  z-index: 1050;
  display: none;
  min-width: 160px;
  width: 480px;
  padding: 0;
  margin: 0;
  font-size: 1.1rem;
  text-align: left;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 
  0 6px 20px rgba(0, 0, 0, 0.19);
}

.layout-dropdown .dropdown-menu.show {
  display: block;
  overflow: auto;
  height: 474px;
  overflow: auto;
}

.compartment-toggle-buttons {
  margin: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 1rem;
  margin-bottom: 1rem;
}

.toggle-button {
  width: 130px;
  height: 38px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle-button.active {
  background-color: #28a798;
  color: white;
}

.internal-layout > .dropdown-compartment {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, auto));
}

.internal-layout > .dropdown-compartment > .dropdown-item  {
  border: 1px solid rgb(242, 242, 242);
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  padding: 1rem;
  clear: both;
  color: #1b1b1b;
  text-align: inherit;
  background-color: transparent;
  text-transform: capitalize;
}

.internal-layout > .dropdown-compartment > .dropdown-item > div {
  height: 130px;
  width: 130px;
  position: relative;
}

.internal-layout > .dropdown-compartment > .dropdown-item > div > img {
  height: 100%;
  width: 100%;
}

.internal-layout > .dropdown-compartment > .dropdown-item > div > span {
  background-color: rgb(255, 255, 255);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 
  0 6px 20px rgba(0, 0, 0, 0.19);
  color: rgb(0, 0, 0);
  width: 110px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 15%;
  top: 75%;
  font-size: 0.85rem;
  font-weight: 600;
  padding: 0.5rem;
}

.dropdown-compartment .dropdown-item:hover,
.dropdown-compartment .dropdown-item:focus {
  background-color: #f8f9fa;
  color: #333;
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
}

@media (max-width: 680px) {
  .layout-dropdown .dropdown-menu {
    left: 1px;
  }

  .layout-dropdown > .dropdown-menu.show {
    width: 450px;
  }
}

@media (max-width: 500px) {
  .layout-dropdown > .dropdown-menu.show {
    width: 400px;
  }
}

@media (max-width: 450px) {
  .layout-dropdown > .dropdown-menu.show {
    width: 350px;
  }
}

@media (max-width: 390px) {
  .layout-dropdown > .dropdown-menu.show {
    width: 320px;
  }
}


/*****************************************/
/* NOTIFICATION */
/****************************************/

/* Example custom styles for Toastify */

.Toastify__toast {
  border-radius: 10px;
  padding: 12px;
  font-size: 0.9rem;
  font-weight: 600;
  color: rgb(18, 18, 18);
}

.Toastify__toast--success .Toastify__progress-bar {
  background-color: #1bba20;  
}

.Toastify__toast--error .Toastify__progress-bar {
  background-color: #b32508;  
}

.Toastify__toast--info .Toastify__progress-bar {
  background-color: #1c8fe2;  
}

.Toastify__toast--warning .Toastify__progress-bar {
  background-color: #958e0e;  
}

.Toastify__progress-bar {
  background-color: #ffeb3b;
}
